import React from 'react';

function Project({name, company, date, desc, skillList}) {
  return (
    <div className="project">
        <div className="project-header">
            <div className="project-name-company">
                <div className="project-name">
                    {name}
                </div>
                <div className="project-company">
                    {company}
                </div>
            </div>
            <div className="project-date">
                {date}
            </div>
        </div>
        <div className="project-body">
            <div className="project-desc">
                {desc}
            </div>
            <div className="project-skills">
                {skillList.map(skill => (
                <div className="skill">{skill}</div>
                ))}
            </div>
        </div>
    </div>
    )
}

export default Project;