import React from 'react';

function SMIcon({icon, link}) {
  return (
    <a href={link} target="_blank">
      <img src={icon} className="social-media-icon" alt=""></img>
    </a>
  )
}

export default SMIcon;