import React from 'react';
import NavBar from './NavBar';
import SMIcon from './SMIcon';
import GitHub from '../images/GitHub.png';
import LinkedIn from '../images/LinkedIn.png';
import Email from '../images/Email.png';

function SideBar({image, name, title, about, activeSection}) {
  return (
    <div className="fixed-element">
      <div className="headshot-container">
        <img src={image} className="headshot-img" alt=""></img>
      </div>
      <div className="name">{name}</div>
      <div className="title">{title}</div>
      <div className="about">{about}</div>
      <NavBar activeSection={activeSection}/>
      <div className="social-media-container">
        <SMIcon icon={GitHub} link="https://github.com/adamsox"/>
        <SMIcon icon={LinkedIn} link="https://www.linkedin.com/in/adamwvking/"/>
        <SMIcon icon={Email} link="mailto:adamwinston01@gmail.com"/>
      </div>
    </div>
  )
}

export default SideBar;