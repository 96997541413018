import React from 'react';

function NavBar({activeSection}) {
  return (
    <nav>
      <ul className="navbar-list">
        <li><a href="#about" className={activeSection === "about" ? "active-section" : "inactive-section"}>About</a></li>
        <li><a href="#experience" className={activeSection === "experience" ? "active-section" : "inactive-section"}>Experience</a></li>
        <li><a href="#projects" className={activeSection === "projects" ? "active-section" : "inactive-section"}>Projects</a></li>
      </ul>
    </nav>
  )
}

export default NavBar;