import React from 'react';

function Heading({title}) {
  return (
      <div className="heading">
        {/* <hr/> */}
        <h2>{title}</h2>
        <hr/>
      </div>
    )
}

export default Heading;