import React, { useEffect, useState } from 'react';
import Heading from './components/Heading.js';
import SideBar from './components/SideBar';
import Experience from './components/Experience';
import Project from './components/Project';
import headshot from './images/headshot.JPG';
import edenLogo from './images/EdenLogo.png';
import ladyGlazeLogo from './images/LadyGlazeLogo.png';
import clubPenguinWave from './images/Club Penguin Wave.png';
import './App.css';

function App() {
  const [activeSection, setActiveSection] = useState(null);

  useEffect(() => {
    const handleScroll = () => {
      const sections = document.querySelectorAll("section");

      let currentSection = null;
      let minDistance = Number.POSITIVE_INFINITY;

      sections.forEach((section) => {
        const rect = section.getBoundingClientRect();
        const centerY = window.innerHeight / 2;

        // Calculate the vertical distance between the center of the viewport and the section
        const distance = Math.abs(rect.top + rect.height / 2 - centerY);

        // If the distance is smaller than the current minimum, update the current section
        if (distance < minDistance) {
          currentSection = section.id;
          minDistance = distance;
        }
      });

      setActiveSection(currentSection);
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll();
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="container">
      <div className="fixed-container">
        <SideBar image={headshot} name="Adam King" title="Software Developer" about="Welcome to my portfolio site!" activeSection={activeSection}></SideBar>
      </div>
      <div className="scroll-container">
        <section id="about">
          <Heading title="About"/>
          <p>In 2011, an 11-year old Club Penguin player decided to try his hand at opening a community website. This was the birth of a strong passion for web development and 12 years later, you're on his very own portfolio website.</p>
          <p>In those 12 years, I developed a strong passion for coding, which led to pursuing it in my education and recently graduating from the University of Guelph with a Bachelor of Computing. I fell in love with problem-solving and thoroughly enjoy coming up with new, efficient solutions and ideas. I learned a lot through my education and my hobbies, and now I'm ready to continue my adventure in the work field.</p>
          <p>One particular area of interest from a very young age has always been web development. I developed an appreciation for clean, visually-appealing user-friendly layouts and interfaces by making my very own Club Penguin websites. And the passion has only grown since then.</p>
          <p>When I'm not coding, you'll probably find me on a soccer field, exploring Canada or playing some of my favourite video games.</p>
          <div className="cp-wave-container">
            <img className="cp-wave" src={clubPenguinWave}></img>
          </div>
        </section>
        <section id="experience">
          <Heading title="Experience"/>
          <Experience image={edenLogo} 
                      name="Eden Energy Equipment" 
                      title="Software Dev/CSR" 
                      startDate="May 2023" 
                      endDate="Present" 
                      dutyList={["Developed hydronic design web app from scratch using for use by team members and customers to accelerate process of designing and quoting hydronic HVAC systems.",
                      "Re-designed e-commerce system to improve UI, increase efficiency and business by releasing to more customers.",
                      "Communicated with customers daily to take orders and handle all warranties and returns."
                      ]}
                      skillList={["React", "JavaScript", "SQL"]}>
          </Experience>
          <Experience image={ladyGlazeLogo} 
                      name="Lady Glaze Doughnuts" 
                      title="Sales Associate" 
                      startDate="May" 
                      endDate="September 2022" 
                      dutyList={["Reconciled cash drawer at start and end of shift, accounted for errors and resolved discrepancies. Key holder, responsible for opening and closing of shop.",
                      "Provided excellent customer service. Prepared beverages, milkshakes and handled food."]}
                      skillList={[]}>
          </Experience>
        </section>
        <section id="projects">
          <Heading title="Projects"/>
          <Project name="Hydronic Design Tool Web App" company="Eden Energy Equipment" date="December 2023" desc="Web app tool developed to accelerate, simplify and increase the efficiency of designing and quoting hydronic HVAC systems. This app brought in interest across the industry in Ontario, expanding Eden Energy's customer base and increasing hydronic business." skillList={["React", "JavaScript", "SQL"]}></Project>
          <Project name="Expense Manager" company="Personal" date="In progress" desc="Web app developed to oversee and manage expenses. Allows for quick entry of data and easy-to-use reporting options to analyze the areas and frequency of user's expenditure." skillList={["React", "JavaScript", "SQL"]}></Project>
          <Project name="Portfolio Website" company="Personal" date="December 2023" desc="Simple, clean website designed to display web development skillset and software engineering experience." skillList={["React", "JavaScript", "nodeJS"]}></Project>
          <Project name="Graphing Website" company="University" date="April 2022" desc="Worked in a team environment to develop front-end and back-end of web app used to create graph maps of different universities' courses, connected based on pre-requisites. Used Playwright to scrape data and Python back-end (using Flask)" skillList={["Flask", "JavaScript", "nodeJS"]}></Project>
          <Project name="GPS Website" company="University" date="April 2021" desc="Developed front-end and back-end of website used to view and interact with GPS data using AJAX calls." skillList={["C", "JavaScript", "AJAX", "nodeJS"]}></Project>
        </section>
      </div>
    </div>
  )
}

export default App;

// const [backendData, setBackendData] = useState([{}])

  // useEffect(() => {
  //   fetch("/api").then(
  //     response => response.json()
  //   ).then(
  //     data => {
  //       setBackendData(data)
  //     }
  //   )
  // }, [])
  // return (
  //   <div>

  //     {(typeof backendData.users === 'undefined') ? (
  //       <p>Loading...</p>
  //     ): (
  //       backendData.users.map((user, i) => (
  //         <p key={i}>{user}</p>
  //       ))
  //     )}

  //   </div>
  // )