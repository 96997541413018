import React from 'react';

function Experience({image, name, title, startDate, endDate, dutyList, skillList}) {
  return (
    <div className="experience">
      <div className="experience-header">
        <div className="company-icon">
          <img src={image} alt="" style={{width: '100%'}}></img>
        </div>
        <div className="company-title">
          <p><span className="job-title">{title}</span>   <strong>&#183;</strong>   <span className="company-name">{name}</span></p>
        </div>
        <div className="company-dates">
          {startDate} - {endDate}
        </div>
      </div>
      <div className="experience-body">
        <div className="company-duties">
          {dutyList.map(duty => (
            <p>{duty}</p>
          ))}
        </div>
        <div className="company-skills">
          {skillList.map(skill => (
            <div className="skill">{skill}</div>
          ))}
        </div>
      </div>
    </div>
    )
}

export default Experience;